















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import moment from 'moment'
import algoliasearch from 'algoliasearch'
import Common from '../../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Paginate from 'vuejs-paginate'
import removeMd from 'remove-markdown'
require("moment-duration-format")

@Component({
  components: {
    ClipLoader,
    Paginate
  },
})
export default class ClinicSearch extends Vue {
  LOAD_COUNT = 10
  rMd = removeMd
  isLoading = true
  viewDetailSearchTab = false
  category = ''
  categories: any[] = []
  viewUserName = ''
  searchCategory = ''
  searchSort = 'performance'
  searchValue = ''
  searchReward = ''
  searchRate = ''
  searchPrefecture = ''
  users: any[] = []
  alreadyReadResult: any = ''
  index = algoliasearch(Common.ALGORIA_APP_ID, Common.ALGORIA_API_KEY).initIndex('users')
  performanceIndex = algoliasearch(Common.ALGORIA_APP_ID, Common.ALGORIA_API_KEY).initIndex('users_performance')
  rewardIndex = algoliasearch(Common.ALGORIA_APP_ID, Common.ALGORIA_API_KEY).initIndex('users_reward')
  rewardDescIndex = algoliasearch(Common.ALGORIA_APP_ID, Common.ALGORIA_API_KEY).initIndex('users_reward_desc')
  pages = 1
  page = 1

  clickCallback(pageNum: number) {
    this.isLoading = true
    this.page = pageNum
    this.$router.replace({name: 'clinicSearch', query: {page: String(pageNum), text: this.searchValue, category: this.$route.query.category}})

    this.search()
  }

  @Watch('$route')
  changeRouter() {
    if (this.category) {
      if (!this.$route.query.category) {
        if (this.category == 'すべてのカテゴリー') {
          return
        }
      } else {
        let category = this.categories.find((category: any) => {
          return category.value == this.$route.query.category
        }).text

        if (category == this.category) {
          return
        }
      }
    }
    
    this.searchValue = ''
    this.searchSort = 'performance'
    this.searchReward = ''
    this.init()
  }

  mounted() {
    this.init()
  }

  init() {
    this.$emit('update:viewTcCategory', true)
    this.$emit('update:viewQsCategory', false)
    this.$emit('update:viewCsCategory', false)
    this.$emit('update:searchType', 'clinic')
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page)
    } else {
      sessionStorage.removeItem('clinicSearch')
    }

    if (this.$route.query.text) {
      this.searchValue = <string>this.$route.query.text
      this.searchPrefecture = <string>this.$route.query.prefecture
      this.$emit('update:searchValue', this.searchValue)
    } else {
      this.$emit('update:searchValue', '')
    }

    let session: any = sessionStorage.getItem('clinicSearch')
    if (session) {
      session = JSON.parse(session)
      this.searchSort = session.searchSort ? session.searchSort : this.searchSort
      this.searchReward = session.searchReward ? session.searchReward : this.searchReward
      if (session.searchReward) {
        this.viewDetailSearchTab = true
      }
    }
    
    Common.getCategory().then((categories: any) => {
      this.categories = categories
      if (this.$route.query.category) {
        this.category = categories.find((category: any) => {
          return category.value == this.$route.query.category
        }).text
        this.searchCategory = <string>this.$route.query.category
      } else {
        this.category = 'すべてのカテゴリー'
        this.searchCategory = ''
      }
      this.search()
    })
  }

  setUser(data: any[]) {
    data.forEach((value, index) => {
      let obj: any = {id: value.objectID, name: value.name, selfIntro: value.selfIntro, skillArray: value.skillArray, minReward: value.minReward, rateNum: value.clinicRateCount ? value.clinicRateCount : 0, sex: value.sex, profession: value.profession, prefecture: value.prefecture}
      obj.url = Common.createUserIconURL(obj.id, value.fileExist)
      obj.rateIcons = Common.createRateIcon(value.clinicRate)
      if (this.searchCategory) {
        let skills = []
        for (let skill in value.skills[this.searchCategory]) {
          skills.push({level: value.skills[this.searchCategory][skill], name: skill})
        }
        skills.sort((a, b) => {
          return b.level - a.level
        })
        obj.skillArray = skills.map((value) => {return value.name})
      }
      if (obj.skillArray.length > 10) {
        obj.skillArray = obj.skillArray.slice(0, 10)
      }
      this.users.push(obj)
    })
  }

  select(user: firebase.firestore.DocumentData) {
    this.$router.push(`/profile?uid=${user.id}`)
  }

  search(conditionChange: boolean = false) {
    this.isLoading = true

    let index = this.performanceIndex
    if (this.searchSort == 'date') {
      index = this.index
    } else if (this.searchSort == 'high_reward') {
      index = this.rewardDescIndex
    } else if (this.searchSort == 'low_reward') {
      index = this.rewardIndex
    }

    if (conditionChange) {
      this.$router.replace('/clinic/search?page=1&category=' + this.searchCategory)
      sessionStorage.setItem('clinicSearch', JSON.stringify({searchSort: this.searchSort, searchReward: this.searchReward}))
    }
    index
      .search({
        query: this.searchValue,
        filters: this.createFilterStr(),
        hitsPerPage: this.LOAD_COUNT,
        page: this.page - 1
      })
      .then((responses: any) => {
        this.users = []
        this.setUser(responses.hits)
        this.pages = Math.ceil(responses.nbHits / this.LOAD_COUNT)
      }).finally(() => {
        this.isLoading = false
      })
  }

  createFilterStr() {
    let filterStr = 'role:"clinic"'
    if (this.searchCategory) {
      filterStr += ` AND categoryArray:"${this.searchCategory}"`
    }
    // 都道府県フィルタ
    if (this.searchPrefecture) {
      filterStr += filterStr ? ' AND ' : ''
      filterStr += `prefecture:"${this.searchPrefecture}"`
    }

    if (this.searchRate) {
      filterStr += ` AND clinicRate >= ${this.searchRate}`
    }
    return filterStr
  }

  sortClick(type: string) {
    if (this.searchSort != type) {
      this.searchSort = type
      this.search(true)
    }
  }
}
